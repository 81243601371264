import {
  SushiLogo,
  BinanceLogo,
  MoonpayLogo,
  CoinbaseLogo,
} from './assets/Logos';

// Links
export const DISCORD_URL = 'https://discord.com/invite/treasuredao';
export const TWITTER_URL = 'https://twitter.com/Treasure_DAO';
export const YOUTUBE_URL = 'https://www.youtube.com/@PlayOnTreasure';
export const TWITCH_URL = 'https://www.twitch.tv/playontreasure';
export const TREASURE_URL = 'https://treasure.lol';
export const PLATFORM_URL = 'https://app.treasure.lol/';
export const GAMES_URL = 'https://app.treasure.lol/games';
export const LEARN_MAGIC_URL =
  'https://docs.treasure.lol/get-started/what-is-magic';
export const NEWSLETTER_URL = 'https://treasuredao.substack.com/';

// Collection Handles
export const LATEST_COLLECTION_HANDLE = 'latest-collection';
export const FRONT_PAGE_COLLECTION_HANDLE = 'frontpage-1';
export const FEATURED_COLLECTION_HANDLE = 'featured';
export const FAVOURITES_COLLECTION = 'latest-collection';

// Tags
export const LIMITED_EDITION_TAG = 'limited';

export const FEATURED_ITEM = 'classic-treasure-cap';

export const EXCLUDE_HANDLES = [
  FRONT_PAGE_COLLECTION_HANDLE,
  FEATURED_COLLECTION_HANDLE,
];

// BUNDLE
export const BUNDLE_HANDLE = 'treasure-box';
export const BUNDLE_PRICE = 330;

// Other
export const MAGIC_PURCHASE_LINK = [
  {
    name: 'Sushi',
    url: ' https://www.sushi.com/swap?%24%7B=&fromChainId=42161&fromCurrency=NATIVE&toChainId=42161&toCurrency=0x539bdE0d7Dbd336b79148AA742883198BBF60342',
    logo: SushiLogo,
  },
  {
    name: 'Moonpay',
    url: 'https://app.treasure.lol/manage-funds',
    logo: MoonpayLogo,
  },
  {
    name: 'Binance',
    url: 'https://www.binance.com/en/trade/MAGIC_USDT?theme=dark&type=spot',
    logo: BinanceLogo,
  },
  {
    name: 'Coinbase',
    url: 'https://exchange.coinbase.com/trade/MAGIC-USD',
    logo: CoinbaseLogo,
  },
];
